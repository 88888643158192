
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { LayoutComponent } from "./layout/layout.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { GuardGuard } from "./guard.guard";
import { NotFoundComponent } from "./not-found/not-found.component";
import { CurrencyconfigviewComponent } from './layout/currencyconfig/currencyconfigview.component';
import { CurrencyconfigeditComponent } from './layout/currencyconfigedit/currencyconfigedit.component';
import { AdminbookingsComponent } from "./layout/adminbookings/adminbookings.component";
import { EmailforotpComponent } from "./emailforotp/emailforotp.component";
import { OtpscreenComponent } from "./otpscreen/otpscreen.component";
import { ResetpasswordComponent } from "./resetpassword/resetpassword.component";
import { UserdetailsComponent } from './layout/userdetails/userdetails.component'; 

import { AdminComponent } from './layout/admin/admin.component';
import { DeliveryboyzonesComponent } from './layout/deliveryboyzones/deliveryboyzones.component';
import { AdminAddComponent } from './layout/admin-add/admin-add.component';
import { SupportticketComponent } from './layout/supportticket/supportticket.component';
import { TicketviewComponent } from './layout/ticketview/ticketview.component';
import{TicketdetailsComponent} from './layout/ticketdetails/ticketdetails.component';
import { PushnotificationsComponent } from './layout/pushnotifications/pushnotifications.component';
import { ReportComponent } from './layout/report/report.component';
import { TripcancellationComponent } from './layout/tripcancellation/tripcancellation.component';
import { OrgComponent } from './layout/org/org.component';
import{configviewComponent} from './layout/config/config.component';
import { SupportListComponent } from "./layout/supportlist/supportlist.component";
import { GroupComponent } from './layout/group/group.component';
import { CreategroupComponent } from './layout/group/creategroup/creategroup.component';
import { ViewgroupComponent } from "./layout/group/viewgroup/viewgroup.component";
import { BanneradsComponent } from "./layout/bannerads/bannerads.component";

import { AddmapzoneComponent } from './layout/addmapzone/addmapzone.component';
import { AppconfigviewComponent } from "./layout/appconfigview/appconfigview.component";
import { VehicleMapComponent } from "./layout/vehicle-map/vehicle-map.component";
import { VendorListComponent } from "./layout/vendor/vendor-list/vendor-list.component";
import { VendorDetailsViewComponent } from "./layout/vendor/vendor-details-view/vendor-details-view.component";
import { TaxsComponent } from "./layout/taxs/taxs.component";
import { PayoutComponent } from "./layout/payout/payout.component";
import { RentailPackageListComponent } from "./layout/rentail-package-list/rentail-package-list.component";
const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "signup", component: RegisterComponent },
  { path: "forgotpassword", component: EmailforotpComponent },
  { path: "otp", component: OtpscreenComponent },
  { path: "resetpassword", component: ResetpasswordComponent },
  // { path: "ticketdetails", component: TicketdetailsComponent },

  {
    path: "dashboard",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: "./layout/dashboard/dashboard.module#DashboardModule",
        canActivate: [GuardGuard],
      },
      {
        path: "Users",
        loadChildren:
          "./layout/user-list/userlistview.module#UserlistviewModule",
        canActivate: [GuardGuard],
      },
      {
      path: "Referal",
      loadChildren:
        "./layout/referal/referal.module#ReferalModule",
      canActivate: [GuardGuard],
    },
      {
        path: "Providers",
        loadChildren:
          "./layout/provider-list/providerlist.module#ProviderlistModule",
        canActivate: [GuardGuard],
      },
      {
        path: "countryView",
        loadChildren: "./layout/country/country.module#CountryModule",
        canActivate: [GuardGuard],
      },
      {
        path: "stateView",
        loadChildren: "./layout/state/state.module#StateModule",
        canActivate: [GuardGuard],
      },
      {
        path: "godsView",
        loadChildren: "./layout/godsview/godsview.module#GodsviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "bookings",
        loadChildren: "./layout/bookings/bookings.module#BookingsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "bookingsView/:id",
        loadChildren:
          "./layout/bookingsview/bookingsview.module#BookingsviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "pushNotificationView",
        loadChildren:
          "./layout/pushnotification/pushnotification.module#PushnotificationModule",
        canActivate: [GuardGuard],
      },{
        path:'adminbookings',
        component:AdminbookingsComponent,
        canActivate:[GuardGuard],
      },
      {
        path:'MapAdd',
        component:VehicleMapComponent,
        canActivate:[GuardGuard],
      }
      ,
      { path: 'ZonesLocation', component: AddmapzoneComponent, canActivate: [GuardGuard] },
      {
        path: "manualBook",
        loadChildren: "./layout/manualbook/manualbook.module#ManualbookModule",
        canActivate: [GuardGuard],
      },
      {
        path: "cityView",
        loadChildren: "./layout/city/city.module#CityModule",
        canActivate: [GuardGuard],
      },
      {
        path: "doctypeView",
        loadChildren: "./layout/doctype/doctype.module#DoctypeModule",
        canActivate: [GuardGuard],
      },
      {
        path: "ridetypeView",
        loadChildren: "./layout/ride-type/ridetype.module#RidetypeModule",
        canActivate: [GuardGuard],
      },
      {
        path: "rideVehicletypeView",
        loadChildren:
          "./layout/ride-type-vehicle/ridetypevehicle.module#RidetypevehicleModule",
        canActivate: [GuardGuard],
      },
      {
        path: "appConfig",
        loadChildren: "./layout/appconfig/appconfig.module#AppconfigModule",
        canActivate: [GuardGuard],
      },
      // {
      //   path: "appConfigView",
      //   // component:AppconfigviewComponent,
      //   loadChildren:
      //     "./layout/appconfigview/appconfigview.module#AppconfigviewModule",
      //   canActivate: [GuardGuard],
      // },
     
      {
        path: "appConfig/:id",
        loadChildren: "./layout/appconfig/appconfig.module#AppconfigModule",
        canActivate: [GuardGuard],
      },
      {
        path: "vehicleBrandView",
        loadChildren:
          "./layout/vehicle-brand/vehiclebrand.module#VehiclebrandModule",
        canActivate: [GuardGuard],
      },
      {
        path: "vehicleModelView",
        loadChildren:
          "./layout/vehicle-model/vehiclemodel.module#VehiclemodelModule",
        canActivate: [GuardGuard],
      },
      {
        path: "cancellationPolicyView",
        loadChildren:
          "./layout/cancellation-policy/cancellationpolicy.module#CancellationpolicyModule",
        canActivate: [GuardGuard],
      },
      {
        path: "staticPagesView",
        loadChildren:
          "./layout/static-pages/staticpages.module#StaticpagesModule",
        canActivate: [GuardGuard],
      },
      {
        path: "staticPagesViewMain",
        loadChildren:
          "./layout/static-pages-Main/staticpages.module#StaticpagesModule",
        canActivate: [GuardGuard],
      },
      {
        path: "emailTemplateView",
        loadChildren:
          "./layout/email-template/emailtemplate.module#EmailtemplateModule",
        canActivate: [GuardGuard],
      },
      {
        path: "appSliderView",
        loadChildren: "./layout/appslider/appslider.module#AppsliderModule",
        canActivate: [GuardGuard],
      },
      // {
      //   path: "providerVehicleView/:id",
      //   loadChildren:
      //     "./layout/group/viewgroup/providervehicleview.module#ProvidervehicleviewModule",
      //   canActivate: [GuardGuard],
      // },
      {
       // path: "Providers/providerVehicleView/:id", 
       path: "providerVehicleView/:id",
        loadChildren:
          "./layout/providervehicleview/providervehicleview.module#ProvidervehicleviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: 'viewgroup/:id',
        //path: "viewgroup",
        component: ViewgroupComponent,
        canActivate: [GuardGuard],
      },
      {
        path: "ticketdetails/:id",
        component:TicketdetailsComponent,
        // loadChildren:"./layout/ticketdetails/ticketdetails.component",
        // loadChildren: "./layout/ticketdetails/ticketdetails.module#ticketdetailsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "deliveryboyZones",
        component:DeliveryboyzonesComponent,
        // loadChildren:"./layout/ticketdetails/ticketdetails.component",
        // loadChildren: "./layout/ticketdetails/ticketdetails.module#ticketdetailsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "userDetailView/:id",
        loadChildren:
          "./layout/userdetailview/userdetailview.module#UserdetailviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "providerListView/:id",
        loadChildren:
          "./layout/provider-listview/providerlistview.module#ProviderlistviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "billing",
        loadChildren:
          "./layout/billingpanel/billingpanel.module#BillingpanelModule",
        canActivate: [GuardGuard],
      },
      {
        path: "companyprofile",
        loadChildren:
          "./layout/companyprofile/companyprofile.module#CompanyprofileModule",
        canActivate: [GuardGuard],
      },
      {
        path: "rental",
        loadChildren:
          "./layout/rentaloutsation/rentaloutstation.module#RentaloutstationModule",
        canActivate: [GuardGuard],
      },
      {
        path: "ridelaterbook",
        loadChildren:
          "./layout/ridelaterbooking/ridelaterbooking.module#RidelaterbookingModule",
        canActivate: [GuardGuard],
      },
      {
        path: "bookingheatmap",
        loadChildren:
          "./layout/bookingheatmap/bookingheatmap.module#BookingheatmapModule",
        canActivate: [GuardGuard],
      },
    
     
      {
        path: "reviewmanagement",
        loadChildren:
          "./layout/reviewmanagement/reviewmanagement.module#ReviewmanagementModule",
        canActivate: [GuardGuard],
      },
      {
        path: "bannerads",
        loadChildren: "./layout/bannerads/bannerads.module#BanneradsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "providercancellation",
        loadChildren:
          "./layout/providercancellation/providercancellation.module#ProvidercancellationModule",
        canActivate: [GuardGuard],
      },
      {
        path: "servicelocation",
        loadChildren:
          "./layout/servicelocation/servicelocation.module#ServicelocationModule",
        canActivate: [GuardGuard],
      },
      {
        path: "edithomepage",
        loadChildren:
          "./layout/edithomepage/edithomepage.module#EdithomepageModule",
        canActivate: [GuardGuard],
      },
      {
        path: "latestnewsupdate",
        loadChildren:
          "./layout/latestnewsupdate/latestnewsupdate.module#LatestnewsupdateModule",
        canActivate: [GuardGuard],
      },
      {
        path: "newslettersubs",
        loadChildren:
          "./layout/newslettersubs/newslettersubs.module#NewslettersubsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "faqs",
        loadChildren: "./layout/faqs/faqs.module#FaqsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "dbbackup",
        loadChildren: "./layout/dbbackup/dbbackup.module#DbbackupModule",
        canActivate: [GuardGuard],
      },
      {
        path: "systemdia",
        loadChildren: "./layout/systemdia/systemedia.module#SystemediaModule",
        canActivate: [GuardGuard],
      },
      {
        path: "locationbased",
        loadChildren:
          "./layout/locationbased/locationbased.module#LocationbasedModule",
        canActivate: [GuardGuard],
      },
      {
        path: "surgepricemanage",
        loadChildren:
          "./layout/surgepricemanage/surgepricemanage.module#SurgepricemanageModule",
        canActivate: [GuardGuard],
      },
      {
        path: "reports",
        loadChildren: "./layout/reports/reports.module#ReportsModule",
        canActivate: [GuardGuard],
      },
      {
        path: "promoCodes",
        loadChildren: "./layout/promocode/promocodes.module#PromocodesModule",
        canActivate: [GuardGuard],
      },
      {
        path: "peekCharges",
        loadChildren:
          "./layout/peekcharges/peekcharges.module#PeekchargesModule",
        canActivate: [GuardGuard],
      },
      {
        path: "withDrawlRequest",
        loadChildren:
          "./layout/withdrawlrequest/withdrawalrequest.module#WithdrawalrequestModule",
        canActivate: [GuardGuard],
      },
      { path: 'currencyConfigView', component: CurrencyconfigviewComponent, canActivate: [GuardGuard] },
      { path: 'currencyConfigEdit/:id', component: CurrencyconfigeditComponent, canActivate: [GuardGuard] },
      {
        path: "userdetails",
        component:UserdetailsComponent,
        canActivate: [GuardGuard],
      }, 

      { path: 'adminview', component: AdminComponent, canActivate: [GuardGuard] },  
      { path: 'addAdmin', component: AdminAddComponent, canActivate: [GuardGuard] },
      {
        path: "supporttickets",
        component:SupportticketComponent,
        canActivate: [GuardGuard],
      },  
      // {
      //   path: "ticketview",
      //   component: TicketviewComponent,
      //   canActivate: [GuardGuard],
      // },  
//       {
//         path: "pushnotifications",
//         // component: PushnotificationsComponent,
        
//  loadChildren:
//           "./layout/pushnotifications/pushnotifications.module#PushnotificationsModule",
//         canActivate: [GuardGuard],
//       },  

{path: "orguser",
component:UserdetailsComponent,
canActivate: [GuardGuard],
},
      {
        path: "report",
        component: ReportComponent,
        canActivate: [GuardGuard],
      },  
      {
        path: "tripcancellation",
        component: TripcancellationComponent,
        canActivate: [GuardGuard],
      },  
      {
        path: "org",
        component: OrgComponent,
        canActivate: [GuardGuard],
      }, 
      {
        path: "ConfigView",
        component:configviewComponent,
        // loadChildren:
        //   "./layout/appconfigview/appconfigview.module#AppconfigviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "appConfigView",
        component:AppconfigviewComponent,
        // loadChildren:
        //   "./layout/appconfigview/appconfigview.module#AppconfigviewModule",
        canActivate: [GuardGuard],
      },
      {
        path: "supportlist",
        component: SupportListComponent,
        canActivate: [GuardGuard],
      }, 
      {
        path: "group",
        component: GroupComponent,
        canActivate: [GuardGuard],
      },  
      // {
      //   path: "bannerads",
      //   component: BanneradsComponent,
      //   canActivate: [GuardGuard],
      // },  
      
      {
        path: "creategroup",
        component: CreategroupComponent,
        canActivate: [GuardGuard],
      },   
      
        
      // Vendor Router
      { path: 'vendors',component: VendorListComponent, canActivate: [GuardGuard]},
      { path: 'vendorView/:id',component: VendorDetailsViewComponent, canActivate: [GuardGuard]},
      { path: 'tax/list',component: TaxsComponent, canActivate: [GuardGuard] },
      { path: 'payout/list',component: PayoutComponent, canActivate: [GuardGuard]},
      { path: 'rental/package/list',component: RentailPackageListComponent, canActivate: [GuardGuard]}
    ], 
    canActivate: [GuardGuard],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy:PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
