import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TaxService } from './taxService/tax.service';
import { environment } from 'src/environments/environment';
import { FormBuilder } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'app-taxs',
  templateUrl: './taxs.component.html',
  styleUrls: ['./taxs.component.css']
})
export class TaxsComponent implements OnInit {

  taxList: any;
  taxState : any;
  isAdd = true;
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('closeEditModal') closeEditModal: ElementRef;
  TaxId = '';
  constructor(private taxService:TaxService,private fb:FormBuilder) { }

  ngOnInit() {
    this.Taxinit();
    this.ListOfTax();
  }

  Taxinit(){
    this.taxState = this.fb.group({
      "Name": [''],
      "Percentage": [''],
      // "IsActive": ['1'],
    })
  }

  AddTax(){
    try {
      this.taxService.AddTax(this.taxState.value).subscribe((res) => {
        console.log('res',res)
        if(res.body['error'] == false){
          environment.swalalert('success',res.body['msg']);
          this.ModalClose();
          this.Taxinit();
          this.ListOfTax();
        }else{
          environment.swalalert('error',res.body['msg']);
        }
      })
    } catch (error) {
      console.log('==Catch Error',error.error.catch);
    }
  }

  ListOfTax(){
    try {
      this.taxService.GetAllTax().subscribe((res) => {
        if(res.body['error'] == false){
          this.taxList = res.body['data'].taxList;
        }else{
          environment.swalalert('error',res.body['msg'])
        }
      })
    } catch (error) {
      console.log('==Catch Error',error.error.catch);
    }
  }

  ModalClose(){   
    this.closeModal.nativeElement.click();
  }

  ViewTax(tax:any){
    this.isAdd = false;
    this.TaxId = tax.Id;
    this.taxService.EditTax(this.TaxId).subscribe((res:any) => {
      let TaxDetail = res['body'].data
      if(res['body'].error == false){
        this.taxState.patchValue({
          "Name": TaxDetail.Name,
          "Percentage": TaxDetail.Percentage,
          // "IsActive": TaxDetail.IsActive
        })
      }
    })    
  }

  EditClose(){
    this.Taxinit();
    this.isAdd = true;
  }

  TaxUpdate(){
    try {
      let params = {
        ...this.taxState.value,
        "Id": this.TaxId
      }
      this.taxService.UpdateTax(params).subscribe((res:any) => {
        if(res['body'].error == false){
          environment.swalalert('success',res.body['msg']);
          this.closeEditModal.nativeElement.click();
          this.Taxinit();
          this.TaxId = '';
          this.isAdd = true;
          this.ListOfTax();
        }else{
          
        }
      })
    } catch (error) {
      console.log('======>Catch Error',error.error.message)
    }
  }


}
