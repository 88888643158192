import { Component, OnInit } from '@angular/core';
import { PayoutService } from './payoutService/payout.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.css']
})
export class PayoutComponent implements OnInit {

  page = 1;
  limit = 20;
  payoutList : any;
  currentTab = 'pending';
  constructor(private payoutService:PayoutService) { }

  ngOnInit() {
    this.ListofPayouts('pending');
  }


  ListofPayouts(status:any){
    try {
      let params = {
        page : this.page,
        limit : this.limit,
        status : status
      }

      this.payoutService.PayoutList(params).subscribe( (res) => {
        if(res.body['error'] == false){
          this.payoutList = res.body['data'].transactions;
        }else{
          environment.swalalert('error',res.body['msg'])
        }
      })
    } catch (error) {
      environment.swalalert('error',error.error.message)
    }
  }

  PayPendingAmt(id:any){
    try { 
      this.payoutService.PaymentPaid(id).subscribe( (res) => {
        if(res.body['error'] == false){
          environment.swalalert('success',res.body['msg']);
          this.ListofPayouts('pending');
        }else{
          environment.swalalert('error',res.body['msg']);
        }
      })
    } catch (error) {
      environment.swalalert('error', error.error.message);
    }
  }


  GetList(status:any){
    try {
      if(status == 'pending'){
        this.currentTab = 'pending';
      }else {
        this.currentTab = 'completed'
      }
      this.page = 1;
      this.payoutList = []
      this.ListofPayouts(status);
    } catch (error) {
      environment.swalalert('error',error.error.message)
    }
  }

}
