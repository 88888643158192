import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { from, Observable } from 'rxjs';
import 'rxjs/Rx';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    // 'Token': this.cookie.get('Token')
    'Token': localStorage.getItem('Token')
  });   
      url = environment.Url;
      private handleError(error: Response) {
        return Observable.throw(error.statusText);
    }
      constructor(private http: HttpClient,
        private cookie: CookieService) { }
      //ref cancellationpolicyedit
       //viewing admin view by NK
 getRolesListView(){
  ////debugger
  const httpheaders = new HttpHeaders({
  //   'Content-Type': [],
  //   'Accept': 'application/json',
  //  //  'Token': this.cookie.get('Token')
   'token': localStorage.getItem('Token'),
    'ngrok-skip-browser-warning' : 'true'
  });
  return this.http.get(`${this.url}/rolesView`,{
    headers: httpheaders,
    observe: 'response'
  });
} 
        getRoles(){
          // ////debugger
          
            const httpheaders = new HttpHeaders({
             
             'token': localStorage.getItem('Token'),
                'ngrok-skip-browser-warning' : 'true'
            });
            return this.http.get(`${this.url}/rolesView`,{
           
              headers: httpheaders,
              observe: 'response'
            });
          }
       }
  

     
     
      
     
    
  