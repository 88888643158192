import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class VendorServiceService {

  BaseUrl = environment.Url;
  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    'Token': localStorage.getItem('Token')
  });
  constructor(private http:HttpClient) { }

  VendorActiveListService(params) {
    const queryParams = new HttpParams()
      .set('Status', 'active')
      .set('page', params.page)
      .set('limit', params.limit);

    return this.http.get(`${this.BaseUrl}/vendors`, { 
      params: queryParams,
      headers: this.httpheaders
     });
  }
  

  vendorInActiveListService(params){
    const queryParams = new HttpParams()
      .set('Status', 'inactive')
      .set('page', params.page)
      .set('limit', params.limit);
      
    return this.http.get(`${this.BaseUrl}/vendors`, { params : queryParams,headers: this.httpheaders });
  }

  VendorDriverList(params){
    const queryParams = new HttpParams()
      .set('VendorId',params.vendorId)
      .set('page', params.page)
      .set('limit', params.limit)
      .set('Status', params.status)

    return this.http.get(`${this.BaseUrl}/vendor/drivers`, { params : queryParams,headers: this.httpheaders });

  }

  CreateVendor(params){
    return this.http.post(`${this.BaseUrl}/vendor/signup`,params,{
      headers: this.httpheaders
    })
  }

  // Update Vendor details (or) Apporve vendor 
  UpdateVendor(id,params){
    return this.http.put(`${this.BaseUrl}/vendors/${ id }`,params,{
      headers: this.httpheaders
    })
  }

  // Get Vendor Details
  GetVendorDet(params){
    return this.http.post(`${this.BaseUrl}/vendorDetails`,params,{
      headers: this.httpheaders
    })
  }
}
