import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class TaxService {
  BaseUrl = environment.Url;
  
  constructor(private http:HttpClient) { }
  httpheaders = new HttpHeaders({   
    'token': localStorage.getItem('Token'),
  });

  AddTax(params){ 
    return this.http.post(`${this.BaseUrl}/addtax`,params,
      {
        headers: this.httpheaders,
        observe: 'response'
      });
  }

  GetAllTax(){
    return this.http.get(`${this.BaseUrl}/gettax`,{
      headers: this.httpheaders,
      observe: 'response'
    })
  }

  EditTax(id){
    return this.http.get(`${this.BaseUrl}/getTaxDetails/${id}`,{
      headers: this.httpheaders,
      observe: 'response'
    })
  }

  UpdateTax(params){
    return this.http.put(`${this.BaseUrl}/updatetax`,params,{
      headers: this.httpheaders,
      observe: 'response'
    })
  }
}
