import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class HourlyPackageService {
  BaseUrl = environment.Url;
  httpheaders = new HttpHeaders({   
    'token': localStorage.getItem('Token'),
  });
  constructor(private http:HttpClient) { }


  PackageList(params){
    const queryParams = new HttpParams()
      .set('page', params.page)
      .set('limit', params.limit); 
    return this.http.get(`${this.BaseUrl}/getrentals`,
      {
        params : queryParams,
        headers: this.httpheaders,
        observe: 'response'
      });
  }

  AddPackage(params){ 
    return this.http.post(`${this.BaseUrl}/addrentals`,params,
      {
        headers: this.httpheaders,
        observe: 'response'
      });
  }

  ViewPackage(id){
    return this.http.get(`${this.BaseUrl}/getRental/${id}`,{
       headers: this.httpheaders,
       observe: 'response'
    })
  }

  EditPackage(params){
    return this.http.put(`${this.BaseUrl}/updateRentals`,params,{
      headers: this.httpheaders,
      observe: 'response'
    })
  }
}
