import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { addadminservies } from './admin-add.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
// import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator, ValidatorFn } from '@angular/forms';
//import { AdminViewModel } from './admin.model';

@Component({
  selector: 'app-admin-add',
  templateUrl: './admin-add.component.html',
  styleUrls: ['./admin-add.component.css']
})
export class AdminAddComponent implements OnInit {

  FirstName : any ;
  LastName : any ;
  Username : any ;
  Mobile:any;
  Email : any ;
  Password : any ;
  Roles : any ;
  RolesResult : any ;
  Status:any;
  status1:any;
  adminAddForm: any;
  check:boolean;
  passCheck:any;
  emailCheck:any;
  existingEmail:any=false;
  existingEmail1:any=false;
  constructor(
    private appspinner: AppComponent,
    private service: addadminservies,
    private router:Router,
    private formBuilder:FormBuilder
  ) { 



    
      
  
  
    
    this.adminAddForm = this.formBuilder.group({
      'FirstName' : [''],
      'LastName' : [''],
      
      'Username' : [''],
      'Mobile' : [''],
      'Email' : ['', [Validators.required, this.emailValidator()]],
      'Password': ['', [Validators.required, this.passwordValidator()]],
      'Role': ['']
      
    });
  }


// Custom password validator function
emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);
    const isValidLength = value.length > 12;

    // const valid = email && isValidLength;
    const isValid = email.test(control.value);
    this.existingEmailCheck(value)
// alert("sdasfsdfdsa"+valid)
this.emailCheck=isValid
    return isValid ? null : { emailRequirements: true };
   
  
  };


  
}


existingEmailCheck(value){
  this.service.getAdminListView(1,"active").subscribe((res) => {
    console.log("data result",res.body['data'])
    if (res.body['error']) {
      environment.swalalert('nodata', res.body['msg']);
      this.appspinner.spinnerAlert('hide');
    } else {
   
    if (res.body['data'][0].data.length > 0 ) {
      for (let i = 0; i < res.body['data'][0].data.length; i++) {
        const email = res.body['data'][0].data[i].Email; // Assuming 'Email' is the property name
        console.log("Email:", email);
    
        // You can perform further operations with the 'email' value
    
        // For example, if you want to check if the email matches a specific value:
        if (email === value) {
          // Do something
          this.existingEmail=true;
        }
        else{
          this.existingEmail=false;
        }
      
    console.log("sssss",res.body['data'][0].data[i].Email)
      }
      res.body['data'][0]=" "
      this.appspinner.spinnerAlert('hide');
    } 
    
    
    
    else {
      environment.swalalert('nodata', 'No Data Available ');
      this.appspinner.spinnerAlert('hide');
    }
  }
  },
    (err) => {
      console.log(err);
      if (err['status'] === 401) {
        environment.swalalert('nodata', err['msg']);
       ///   localStorage.removeItem('Token');
        //  this.router.navigate(['login']);
      }
    });

   
    this.service.getAdminListView(1,"pending").subscribe((res1) => {
      console.log("data resultdddd",res1.body['data'])
      if (res1.body['error']) {
        environment.swalalert('nodata', res1.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
     
      if (res1.body['data'][0].data.length > 0 ) {
        for (let i = 0; i < res1.body['data'][0].data.length; i++) {
          const email1 = res1.body['data'][0].data[i].Email; // Assuming 'Email' is the property name
          console.log("Emaildddd:", email1);
      
          // You can perform further operations with the 'email' value
      
          // For example, if you want to check if the email matches a specific value:
          if (email1 === value) {
            // Do something
           
            this.existingEmail1=true;
          }
          else{
            this.existingEmail1=false;
          }
        
      console.log("sssssdddd",res1.body['data'][0].data[i].Email)
        }
      
        this.appspinner.spinnerAlert('hide');
      } 
      
      
      
      else {
        environment.swalalert('nodata', 'No Data Available ');
        this.appspinner.spinnerAlert('hide');
      }
    }
    },
      (err) => {
        console.log(err);
        if (err['status'] === 401) {
          environment.swalalert('nodata', err['msg']);
         ///   localStorage.removeItem('Token');
          //  this.router.navigate(['login']);
        }
      });

}
  emailvalidate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
      return null; // Empty value is considered valid
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailRegex.test(control.value);
    return isValid ? null : { invalidEmail: true };








  }
  // Custom password validator function
  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const hasUppercase = /[A-Z]/.test(value);
      const hasLowercase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);
      const isValidLength = value.length >= 9;

      const valid = hasUppercase && hasLowercase && hasNumber && hasSpecialChar && isValidLength;
// alert("sdasfsdfdsa"+valid)
this.passCheck=valid
      return valid ? null : { passwordRequirements: true };
     
    
    };
  }

  ngOnInit() {
    // this.existingEmailCheck()
    this.service.getRolesListView().subscribe((res) => {
      console.log("data result",res.body['data'])
      if (res.body['error']) 
      {
        environment.swalalert('nodata', res.body['msg']);
        this.appspinner.spinnerAlert('hide');
      } else {
      console.log("data from roles view ", res.body['data'][0].Id);
this.RolesResult = res.body['data']
             }
    })
  }

  onChange(value)
  {
    console.log('========>',value)
    this.Roles = value
  }
  onChange1(Status)
  {
    this.Status = Status
  }
  addingAdmin()
  {
    ////debugger

  //   var aa=  this. adminAddForm.get('Password').hasError('passwordRequirements')
  //  this. passwordValidator()
  // alert(this.passCheck)
 


    if(this.Status== "on")
    {
      this.status1="active"
    }
    else{
      this.status1="inactive"
    }
    
    var sF = this.adminAddForm.value;
    var data = {
      FirstName : sF.FirstName,
      LastName : sF.LastName,
      Username : sF.Username,
      Mobile:sF.Mobile,
      Email : sF.Email,
      Password : sF.Password,
      Roles : sF.Role,
      Status:this.status1
    }
//     var data = {
//       FirstName : this.FirstName,
//       LastName : this.LastName,
//       Username : this.Username,
//       Mobile:this.Mobile,
//       Email : this.Email,
//       Password : this.Password,
//       Roles : this.Roles,
// Status:this.status1
//     }
    // console.log("vhgfghfghf",data)
    // if(this.passCheck==true){
      // if (this.existingEmail=== false && this.existingEmail1=== false ){

      // if (this.passCheck === true && this.emailCheck === true) {
        if (this.passCheck === true ) {
    this.service.addadmin(data).subscribe(res => {
      if (res['error']) {
        environment.swalalert('nodata', res['msg']);
        this.appspinner.spinnerAlert('hide');
      } 
      else {
      if(res.body['error'] === false ) {
        environment.swalalert('success',res.body['msg']).then(value => {
          if(value) {
        //    this.peakchargeedit.reset();
            this.router.navigate(['/adminview']);
          }
          });
      } 
      else {
        environment.swalalert('error',res.body['msg']);
      }
    }
    });
  // }
  




  }
  else {
    environment.swalalert('error',"Enter Correct Password");
  }
  // }
  // else
  // {
  //   environment.swalalert('error',"Entered email already exists");
  // }


  }
  canceladdingAdmin(){
    this.router.navigate(['/adminview']);
  }
}
