import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PayoutService {

  BaseUrl = environment.Url;
  httpheaders = new HttpHeaders({
    'Content-Type': [],
    'Accept': 'application/json',
    'Token': localStorage.getItem('Token')
  });

  constructor(private http:HttpClient) { }

  // Vendor Payout list
  PayoutList(params){

    const queryParams = new HttpParams()
    .set('status',params.status)
    .set('page', params.page)
    .set('limit', params.limit);
    console.log('Query',queryParams)
    return this.http.get(`${this.BaseUrl}/vendor/payouts`,{
      params: queryParams,
      headers: this.httpheaders,
      observe: 'response'
    })
  }

  PaymentPaid(payoutId){
    const queryParams = new HttpParams()
    .set('status','completed')
    .set('payoutId', payoutId);

    const body = {};

    return this.http.patch(`${this.BaseUrl}/vendor/updatepayouts`,body,{
      params: queryParams,
      headers: this.httpheaders,
      observe: 'response'
    })
  }
}
