import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GuardGuard } from 'src/app/guard.guard';
import { EncryService } from 'src/app/encry.service';
import { from, empty } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { BanneradsaddService } from '../bannerads/banneradsadd/banneradsadd.service';

import { BannerAdsAddModel } from '../bannerads/banneradsadd/banneradd.model';
@Component({
  selector: 'app-vehicle-map',
  templateUrl: './vehicle-map.component.html',
  styleUrls: ['./vehicle-map.component.css']
})
export class VehicleMapComponent implements OnInit {

  @ViewChild('img') img: ElementRef;
  banneradsAddForm: any;
  imagefile: File[];
  files: any;
  imagefilename: any;
  banneradsmodel: BannerAdsAddModel;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private guard: GuardGuard,
    private encry: EncryService,
    private service: BanneradsaddService,
    private appspinner: AppComponent
  ) {
    this.banneradsAddForm = this.formBuilder.group({
     
      
      'file': ['', [Validators.required]],
      'Url': ['', [Validators.required, Validators.minLength(1)]],
      
    });
    
   }

  ngOnInit() {
    this.appspinner.spinnerAlert('show');
    this.appspinner.spinnerAlert('hide');
  }


  readURL(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imagefilename = reader.result;
      reader.readAsDataURL(file);
    }
  }

  appslideradd() {
    ////debugger

    this.imagefile = this.img.nativeElement.files;
    if (this.banneradsAddForm.valid) {
      var sF = this.banneradsAddForm.value;
      this.service.fileupload(this.imagefile, 'banner').subscribe((res) => {
        if (res['error']) {
          environment.swalalert('nodata', res['msg']);
          this.appspinner.spinnerAlert('hide');
        } else {
          var status = '';
          if (sF.IsActive === true) {
            status += 'Active';
          } else {
            status += 'InActive';
          }
          if (res.body['error'] === false) {
            this.banneradsmodel = new BannerAdsAddModel(
              sF.Title,
              sF.Description,
              res.body['data'].url,
              sF.Url,
              status
            );
            ////debugger
            this.service.banneradsadd(this.banneradsmodel).subscribe((res) => {
              if (res.body['error'] === false) {
                environment.swalalert('success', res.body['msg']).then(value => {
                  if (value) {
                    this.router.navigate(['bannerads']);
                  }
                });
              } else {
                environment.swalalert('error', res.body['msg']);
              }
            });

          } else {
            environment.swalalert('error', res.body['msg']);
          }
        }
      });
    }
    else {
      environment.swalalert('warning', 'All Fields Required');
    }
  }


  Clearppslideradd()
  {
  //   this.banneradsAddForm = this.formBuilder.group({
  //     'Title': [''],
  //     'Description': [''],
  //     'file': [''],
  //     'Url': [''],
  //     'IsActive': ['']
  //   });
    this.banneradsAddForm.reset();
   // this.router.navigate(['bannerads']);
  }

}
